import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import Schema from './schema';

const SEO = ({
  description,
  lang,
  title,
  noIndex,
  extraTags,
  canonical,
  datePublished,
  author,
  image,
}) => {
  const {
    site: { siteMetadata },
    defaultCover,
    integrationsCover,
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteUrl
            organization {
              name
              url
              logo
            }
            social {
              twitter
              fbAppId
            }
          }
        }
        defaultCover: file(relativePath: { eq: "defaultCover.png" }) {
          childImageSharp {
            fluid {
              src
            }
          }
        }
        integrationsCover: file(
          relativePath: { eq: "marketplace/covers/defaultCoverIntegrations.png" }
        ) {
          childImageSharp {
            fluid {
              src
            }
          }
        }
      }
    `,
  );
  const pattern = new RegExp(/blog\/./gm);
  const isBlogPostPage = canonical ? pattern.test(canonical) : false;
  const defaultImage = defaultCover.childImageSharp.fluid.src;

  const metaTitle = title || siteMetadata.title;
  const metaDescription = description || siteMetadata.description;

  const hrefCanonical = `${process.env.GATSBY_HOST}${canonical}`;

  const defaultIntegrationsCover =
    canonical &&
    canonical.split('/')[1] === 'integrations' &&
    integrationsCover.childImageSharp.fluid.src;

  const img = image || defaultIntegrationsCover || defaultImage;

  const imageLink = `${process.env.GATSBY_HOST}${img}`;

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang,
        }}
        title={title}
        titleTemplate={
          canonical
            ? canonical.includes('blog') || canonical === '/'
              ? null
              : `%s | LiveSession`
            : `%s | LiveSession`
        }
      >
        {/* General tags */}
        <title>{title}</title>
        <meta name="description" content={metaDescription} />
        <meta name="image" content={imageLink} />
        {canonical && <link rel="canonical" href={hrefCanonical} />}

        {/* OpenGraph tags */}
        {canonical && <meta property="og:url" content={hrefCanonical} />}
        {canonical ? (
          canonical.includes('blog/post') ? (
            <meta property="og:type" content="article" />
          ) : (
            <meta property="og:type" content="website" />
          )
        ) : (
          <meta property="og:type" content="website" />
        )}
        <meta property="og:title" content={metaTitle} />
        <meta property="og:description" content={metaDescription} />
        <meta property="og:image" content={imageLink} />
        <meta property="og:image:width" content={1287} />
        <meta property="og:image:height" content={685} />
        <meta property="fb:app_id" content={siteMetadata.social.fbAppId} />

        {/* Twitter Card tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content={siteMetadata.organization.name} />
        <meta name="twitter:title" content={metaTitle} />
        <meta name="twitter:description" content={metaDescription} />
        <meta name="twitter:site" content={siteMetadata.social.twitter} />
        <meta name="twitter:image" content={imageLink} />
        {noIndex && <meta name="robots" content="noindex" />}
        {extraTags}
      </Helmet>
      <Schema
        isBlogpost={isBlogPostPage}
        url={hrefCanonical}
        title={isBlogPostPage ? metaTitle : `${metaTitle} | LiveSession`}
        description={metaDescription}
        datePublished={datePublished}
        author={author || 'LiveSession'}
        organization={siteMetadata.organization}
        image={imageLink}
        defaultTitle={siteMetadata.title}
      />
    </>
  );
};

SEO.defaultProps = {
  lang: `en`,
  description: ``,
  noIndex: false,
  extraTags: null,
  datePublished: '',
  image: null,
  canonical: undefined,
};

SEO.propTypes = {
  noIndex: PropTypes.bool,
  extraTags: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
  // eslint-disable-next-line consistent-return
  canonical: (props, propName, componentName) => {
    if (props.noIndex !== true && props.canonical === undefined) {
      return new Error(`Please provide a ${propName} link in ${componentName}!`);
    }
  },
  datePublished: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  author: PropTypes.string.isRequired,
  image: PropTypes.string,
  description: PropTypes.string,
  lang: PropTypes.string,
  title: PropTypes.string.isRequired,
};

export default SEO;
