/* eslint-disable */
/* eslint-disable-prettier */
import * as queryString from 'query-string';
import Cookies from 'js-cookie';
import { merge, forOwn, isEmpty } from 'lodash';
import setAfiDimensionGA from './tracking-ga';

const track = () => {
  if (window.location.search) {
    const parsedQuery = queryString.parse(window.location.search);
    const {
      utm_source = '',
      utm_medium = '',
      utm_campaign = '',
      utm_term = '',
      utm_content = '',
      promo = '',
      a = '',
    } = parsedQuery;
    const source = {
      utm_source: convert(utm_source),
      utm_medium: convert(utm_medium),
      utm_campaign: convert(utm_campaign),
      utm_term: convert(utm_term),
      utm_content: convert(utm_content),
    };

    const afi = convert(a);

    const clearedSource = omitEmptyParams(source);

    if (afi.length > 0) {
      saveAffi(afi);
      setAfiDimensionGA(afi);
      // sendEventToPartners(afi, source.utm_source, source.utm_campaign, source.utm_medium);
    }

    if (!isEmpty(clearedSource)) {
      saveSource(clearedSource);
    }

    updateSession(convert(promo));
  } else {
    updateSession();
  }
};

// const sendEventToPartners = (affiID, utm_source, utm_campaign, utm_medium) => {
//     let visitorID = Cookies.get('__lsw_visitor');

//     const body = {
//         affi_id: affiID,
//         ...(visitorID && {visitor_id: visitorID}),
//         ...(utm_source.length > 0 && {utm_source: utm_source}),
//         ...(utm_medium.length > 0 && {utm_medium: utm_medium}),
//         ...(utm_campaign.length > 0 && {utm_campaign: utm_campaign})
//     }

//     fetch(`${process.env.GATSBY_PP_API_URL}/visits`,
//     {
//         method: 'POST',
//         body: JSON.stringify(body)
//     })
//     .then(res => {
//         if(res.status === 200) {
//             const { visitor } = res.json();
//             if(!visitorID && visitor) {
//                 Cookies.set('__lsw_visitor', visitor, {path: '/', domain: '.livesession.io', expires:10000});
//             }
//         } else {
//             Promise.reject(new Error('Partner API error'));
//         }
//     })
//     .catch(e => {})

// }

// To eliminate array query params
const convert = (param) => {
  if (typeof param === 'string') {
    return param;
  }

  return '';
};

const checkDomain = (referrer) => referrer.includes('livesession.io');

const updateSession = (promo) => {
  const last_session = Cookies.get('__lsw_session');
  const timeExpires = new Date(new Date().getTime() + 30 * 60 * 1000);
  const { referrer } = document;

  if (last_session) {
    const sessionBody = JSON.parse(last_session);

    if (promo && promo.length > 0) {
      sessionBody.promo_code = promo;
    }

    Cookies.set('__lsw_session', JSON.stringify(sessionBody), {
      expires: timeExpires,
    });
  } else {
    Cookies.set(
      '__lsw_session',
      JSON.stringify({
        landing_page: window.location.pathname,
        ...(referrer.length > 0 && !checkDomain(referrer) && { referrer }),
        ...(promo && promo.length > 0 && { promo_code: promo }),
      }),
      {
        expires: timeExpires,
      },
    );
  }
};

const saveAffi = (a) => {
  if (a) {
    Cookies.set('__lsw_afi', a, {
      expires: 90,
    });
  }
};

const saveSource = (source) => {
  if (source) {
    Cookies.set('__lsw_last_source', JSON.stringify(source), {
      expires: 1000,
    });
  }
};

const getSourceID = () => {
  const { source_id = '' } = queryString.parse(window.location.search);

  const result = convert(source_id);

  if (result.length > 0) {
    return { source_id: result };
  }
  return {};
};

const getSourceURL = () => {
  const { url_source = '' } = queryString.parse(window.location.search);

  const result = convert(url_source);

  if (result.length > 0) {
    return { url_source: result };
  }
  return {};
};

const getSession = () => {
  const sessionBody = Cookies.get('__lsw_session');

  if (sessionBody) {
    return JSON.parse(sessionBody);
  }

  return {};
};

const getSource = () => {
  const source = Cookies.get('__lsw_last_source');

  if (source) {
    return JSON.parse(source);
  }

  return {};
};

const getPartnerID = () => {
  const afi = Cookies.get('__lsw_afi');

  if (afi) {
    return { partner_id: afi };
  }
  return {};
};

const omitEmptyParams = (tracking) => {
  const result = {};

  forOwn(tracking, (v, k) => {
    if (v.length > 0) {
      result[k] = v;
    }
  });

  return result;
};

/*
 * Returns tracking parameters (utms, sources, referrers, promo codes, afiliate id)
 * Omits empty paramters
 */
const getTrackingValues = () => {
  const source = getSource();
  const partnerID = getPartnerID();
  const sourceURL = getSourceURL();
  const sourceID = getSourceID();
  const session = getSession();
  const result = omitEmptyParams(merge({}, source, partnerID, session, sourceID, sourceURL));

  return result;
};

export { getTrackingValues, track };

export default track;
