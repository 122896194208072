import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

const Schema = ({
  isBlogpost,
  url,
  title,
  description,
  datePublished,
  author,
  defaultTitle,
  organization,
  image,
}) => {
  const baseSchema = [
    {
      '@context': 'http://schema.org',
      '@type': 'WebSite',
      url,
      name: title,
      alternateName: defaultTitle,
    },
  ];
  const schema = isBlogpost
    ? [
        ...baseSchema,
        {
          '@context': 'http://schema.org',
          '@type': 'BreadcrumbList',
          itemListElement: [
            {
              '@type': 'ListItem',
              position: 1,
              name: 'LiveSession',
              item: 'https://livesession.io/',
            },
            {
              '@type': 'ListItem',
              position: 2,
              name: 'Blog',
              item: 'https://livesession.io/blog/',
            },
            {
              '@type': 'ListItem',
              position: 3,
              name: title,
              item: url,
            },
          ],
        },
        {
          '@context': 'http://schema.org',
          '@type': 'BlogPosting',
          url,
          name: title,
          alternateName: defaultTitle,
          headline: title,
          image: {
            '@type': 'ImageObject',
            url: image,
          },
          description,
          author: {
            '@type': 'Person',
            name: author,
          },
          publisher: {
            '@type': 'Organization',
            url: organization.url,
            logo: {
              '@type': 'ImageObject',
              url: organization.logo,
            },
            name: organization.name,
          },
          mainEntityOfPage: {
            '@type': 'WebSite',
            '@id': url,
          },
          dateModified: datePublished,
          datePublished,
        },
      ]
    : baseSchema;

  return (
    <Helmet>
      {/* Schema.org tags */}
      <script type="application/ld+json">{JSON.stringify(schema)}</script>
    </Helmet>
  );
};

export default Schema;

Schema.propTypes = {
  isBlogpost: PropTypes.bool,
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  datePublished: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]).isRequired,
  author: PropTypes.string.isRequired,
  defaultTitle: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  organization: PropTypes.object.isRequired,
  image: PropTypes.string.isRequired,
};

Schema.defaultProps = {
  isBlogpost: false,
};
