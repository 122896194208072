import React from 'react';
import PropTypes from 'prop-types';

const Hamburger = ({ showMobileMenu, font, onClick }) => {
  return (
    <button
      style={{
        fontWeight: 700,
        color: showMobileMenu ? '#000' : font,
      }}
      type="button"
      data-toggle="collapse"
      data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
      className="navbar-toggler navbar-toggler-right"
      onClick={onClick}
    >
      Menu
      <span className={['hamburger__box', showMobileMenu && 'hamburger--active'].join(' ')}>
        <span
          className="hamburger__inner__before"
          style={{
            backgroundColor: showMobileMenu ? '#000' : font || '#000',
          }}
        />
        <span
          className="hamburger__inner"
          style={{
            backgroundColor: showMobileMenu ? '#000' : font || '#000',
          }}
        />
        <span
          className="hamburger__inner__after"
          style={{
            backgroundColor: showMobileMenu ? '#000' : font || '#000',
          }}
        />
      </span>
    </button>
  );
};

export default Hamburger;

Hamburger.propTypes = {
  showMobileMenu: PropTypes.bool,
  font: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

Hamburger.defaultProps = {
  showMobileMenu: false,
  font: null,
};
