import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';
import { useSourceParams } from 'hooks/useSourceParams';

const loading = keyframes`
  0%{
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const StyledButton = styled.a`
  background-color: ${({ secondary, reversed }) =>
    secondary ? (reversed ? '#fff' : '#000') : reversed ? '#fff' : '#0A4ED6'};
  padding: ${({ small }) => (small ? '7px 20px' : '12px 28px')};
  border: ${({ secondary }) => (secondary ? '2px solid #000' : '2px solid #0A4ED6')};
  border-radius: 4px;
  transition: 0.2s ease;
  max-height: ${(props) => (props.small ? '38px' : '48px')};
  font-size: 16px;
  color: ${({ secondary, reversed }) =>
    secondary ? (reversed ? '#000' : '#fff') : reversed ? '#0A4ED6' : '#fff'};
  font-weight: 800;
  text-align: center;
  cursor: pointer;
  min-width: 140px;
  outline: 0;
  display: inline-block;
  text-decoration: none;
  white-space: nowrap;
  margin: ${(props) => (props.center ? '0 auto' : 0)};
  line-height: normal;
  &:focus {
    color: #fff;
  }
  &:hover {
    color: ${({ secondary, reversed }) =>
      secondary ? (reversed ? '#fff' : '#000') : reversed ? '#fff' : '#0A4ED6'};
    background-color: ${({ secondary, reversed }) =>
      secondary ? (reversed ? '#000' : 'transparent') : reversed ? '#0A4ED6' : 'transparent'};
    outline: 0;
    text-decoration: none;
  }
  &:disabled {
    background-color: #bfbfbf;
    border-color: #bfbfbf;
    &:hover {
      color: white;
      box-shadow: none;
      transform: none;
      background-color: #dfdfe2;
      border-color: #dfdfe2;
    }
    & .lds-ring {
      display: inline-flex;
      transform: translate(-16px, 2px);
      opacity: 1;
    }
  }
  & .lds-ring {
    display: none;
    position: relative;
    width: 16px;
    height: 16px;
    transition: 0.2s ease;
    opacity: 0;
    transition: all 0.2s ease;
    transform: translate(0, 2px);
    & div {
      box-sizing: border-box;
      display: block;
      position: absolute;
      width: 16px;
      height: 16px;
      margin: 0;
      border: 2px solid #fff;
      border-radius: 50%;
      animation: ${loading} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      border-color: #fff transparent transparent transparent;
      &:nth-child(1) {
        animation-delay: -0.45s;
      }
      &:nth-child(2) {
        animation-delay: -0.3s;
      }
      &:nth-child(3) {
        animation-delay: -0.15s;
      }
    }
  }
`;

const Button = ({
  as,
  href,
  children,
  onClick,
  small,
  secondary,
  center,
  type,
  sourceID,
  signUp,
  className,
  disabled,
  id,
  style,
  target,
  rel,
  reversed,
  withLoading,
  to,
}) => {
  const { link } = useSourceParams(sourceID);
  return (
    <StyledButton
      as={as}
      href={signUp ? link : href}
      onClick={onClick}
      small={small}
      secondary={secondary}
      center={center}
      type={type}
      className={className}
      disabled={disabled}
      id={id}
      style={style}
      target={target}
      rel={rel}
      reversed={reversed}
      to={to}
    >
      {withLoading && (
        <div className="lds-ring">
          <div />
          <div />
          <div />
          <div />
        </div>
      )}
      {children}
    </StyledButton>
  );
};

export default Button;

Button.propTypes = {
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.node]),
  // eslint-disable-next-line consistent-return
  href: (props, propName, componentName) => {
    if (props.signUp === true && props[propName] !== undefined) {
      return new Error(
        `Please provide "signup" prop instead of "href" if you want to redirect to /signup, remember to pass also sourceID, in ${componentName}!`,
      );
    }
  },
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.node]).isRequired,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]),
  style: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]),
  onClick: PropTypes.func,
  small: PropTypes.bool,
  secondary: PropTypes.bool,
  center: PropTypes.bool,
  type: PropTypes.string,
  signUp: PropTypes.bool,
  disabled: PropTypes.bool,
  reversed: PropTypes.bool,
  withLoading: PropTypes.bool,
  id: PropTypes.string,
  target: PropTypes.string,
  rel: PropTypes.string,
  to: PropTypes.string,
  // eslint-disable-next-line consistent-return
  sourceID: (props, propName, componentName) => {
    if (props.signUp === true && typeof props[propName] !== 'string') {
      return new Error(
        `Please provide a sourceID and signUp props instead of href in ${componentName}!`,
      );
    }
  },
};

Button.defaultProps = {
  as: null,
  href: undefined,
  onClick: null,
  small: false,
  secondary: false,
  center: false,
  type: null,
  sourceID: undefined,
  to: undefined,
  signUp: false,
  className: null,
  id: null,
  style: null,
  target: null,
  rel: null,
  disabled: false,
  reversed: false,
  withLoading: false,
};
