import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery, navigate } from 'gatsby';
import Link from 'components/ui/link';
import imgFB from 'img/footer/facebook.svg';
import imgLI from 'img/footer/linkedin.svg';
import imgTwitter from 'img/footer/twitter.svg';
import imgInsta from 'img/footer/instagram.png';
import imgGithub from 'img/footer/github.svg';
import { CustomButton } from 'components/ui/startUsing/styled';
import { useSourceParams } from 'hooks/useSourceParams';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import * as Styled from './styled';

const Footer = ({ adPage }) => {
  const { link } = useSourceParams('footer-input');
  const [email, setEmail] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    navigate(`${link}&email=${email}`);
  };

  const {
    allJavascriptFrontmatter: { edges },
  } = useStaticQuery(graphql`
    query FooterBlogArticles {
      allJavascriptFrontmatter(
        filter: { frontmatter: { url: { regex: "/blog/" }, group: { ne: "updates" } } }
        sort: { fields: frontmatter___date, order: DESC }
        limit: 3
      ) {
        edges {
          node {
            frontmatter {
              url
              title
            }
          }
        }
      }
    }
  `);
  return (
    <footer className="main-footer">
      {!adPage && (
        <div className="container">
          <Styled.ContainerBox>
            <Styled.GridBox>
              <div>
                <p className="footer-heading">Product</p>
                <Styled.List className="list-unstyled m-0">
                  <li>
                    <Link href="/pricing/" className="footer-link">
                      Pricing
                    </Link>
                  </li>
                  <li>
                    <Link href="/integrations/" className="footer-link">
                      Integrations
                    </Link>
                  </li>
                  <li>
                    <Link href="/updates/" className="footer-link">
                      Updates
                    </Link>
                  </li>
                </Styled.List>
                <p className="footer-heading">Features</p>
                <Styled.List className="list-unstyled m-0">
                  <li>
                    <Link tag={AnchorLink} to="/features#session-replay" className="footer-link">
                      Session replays
                    </Link>
                  </li>
                  <li>
                    <Link tag={AnchorLink} to="/clickmaps/" className="footer-link">
                      Clickmaps
                    </Link>
                  </li>
                  <li>
                    <Link
                      tag={AnchorLink}
                      to="/features#filters-and-segments"
                      className="footer-link"
                    >
                      Filters and segments
                    </Link>
                  </li>
                  <li>
                    <Link tag={AnchorLink} to="/features#engagement-score" className="footer-link">
                      Engagement score
                    </Link>
                  </li>
                  <li>
                    <Link tag={AnchorLink} to="/features#custom-properties" className="footer-link">
                      Custom properties
                    </Link>
                  </li>
                  <li>
                    <Link
                      tag={AnchorLink}
                      to="/features#rage-and-error-clicks"
                      className="footer-link"
                    >
                      Rage and error clicks
                    </Link>
                  </li>
                  <li>
                    <Link tag={AnchorLink} to="/features#inspect-mode" className="footer-link">
                      Inspect mode
                    </Link>
                  </li>
                  <li>
                    <Link tag={AnchorLink} to="/features#insights" className="footer-link">
                      Insights
                    </Link>
                  </li>
                </Styled.List>
              </div>
              <div>
                <p className="footer-heading custom-heading">Solutions</p>
                <Styled.List className="list-unstyled m-0">
                  <li>
                    <Link
                      tag={AnchorLink}
                      to="/solutions#conversion-optimization"
                      className="footer-link"
                    >
                      Conversion optimization
                    </Link>
                  </li>
                  <li>
                    <Link
                      tag={AnchorLink}
                      to="/solutions#user-engagement-tracking"
                      className="footer-link"
                    >
                      User engagement tracking
                    </Link>
                  </li>
                  <li>
                    <Link
                      tag={AnchorLink}
                      to="/solutions#usability-testing"
                      className="footer-link"
                    >
                      Usability testing
                    </Link>
                  </li>
                  <li>
                    <Link
                      tag={AnchorLink}
                      to="/solutions#application-debugging"
                      className="footer-link"
                    >
                      Application debugging
                    </Link>
                  </li>
                  <li>
                    <Link tag={AnchorLink} to="/solutions#customer-support" className="footer-link">
                      Customer support
                    </Link>
                  </li>
                  <li>
                    <Link
                      tag={AnchorLink}
                      to="/solutions#performance-marketing"
                      className="footer-link"
                    >
                      Performance marketing
                    </Link>
                  </li>
                </Styled.List>
                <p className="footer-heading">Programs</p>
                <Styled.List className="list-unstyled m-0">
                  <li>
                    <Link href="/partners/" className="footer-link">
                      Affiliate program
                    </Link>
                  </li>
                  <li>
                    <Link href="/early-stage-program/" className="footer-link">
                      Early stage
                    </Link>
                  </li>
                  <li>
                    <Link href="/badge/" className="footer-link">
                      Get free sessions
                    </Link>
                  </li>
                </Styled.List>
                <div className="mobile-view">
                  <p className="footer-heading">Company</p>
                  <Styled.List className="list-unstyled m-0">
                    <li>
                      <Link href="/demo/" className="footer-link">
                        Get a demo
                      </Link>
                    </li>
                    <li>
                      <Link href="/contact/" className="footer-link">
                        Contact Us
                      </Link>
                    </li>
                    <li>
                      <Link href="/jobs/" className="footer-link">
                        Careers
                      </Link>
                    </li>
                  </Styled.List>
                </div>
              </div>
            </Styled.GridBox>
            <Styled.GridBox secondary>
              <div>
                <p className="footer-heading">Customers</p>
                <Styled.List className="list-unstyled m-0">
                  <li>
                    <Link href="/customers/" className="footer-link">
                      Case studies
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="https://g2crowd.com/products/livesession/reviews"
                      target="_blank"
                      rel="nofollow noopener noreferrer"
                      className="footer-link"
                    >
                      G2 Crowd
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="https://www.capterra.com/p/182661/LiveSession/"
                      target="_blank"
                      rel="nofollow noopener noreferrer"
                      className="footer-link"
                    >
                      Capterra
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="https://www.getapp.com/business-intelligence-analytics-software/a/livesession/"
                      target="_blank"
                      rel="nofollow noopener noreferrer"
                      className="footer-link"
                    >
                      GetApp
                    </Link>
                  </li>
                </Styled.List>
                <p className="footer-heading">Compare with</p>
                <Styled.List className="list-unstyled m-0">
                  <li>
                    <Link href="/compare/fullstory-alternative/" className="footer-link">
                      FullStory
                    </Link>
                  </li>
                  <li>
                    <Link href="/compare/hotjar-alternative/" className="footer-link">
                      Hotjar
                    </Link>
                  </li>
                </Styled.List>
                <p className="footer-heading">Industries</p>
                <Styled.List className="list-unstyled m-0">
                  <li>
                    <Link href="/industry/ecommerce/" className="footer-link">
                      eCommerce
                    </Link>
                  </li>
                </Styled.List>
              </div>
              <div>
                <p className="footer-heading">Resources</p>
                <Styled.List className="list-unstyled m-0">
                  <li>
                    <Link
                      href="https://developers.livesession.io/"
                      target="_blank"
                      className="footer-link"
                      rel="noopener noreferrer"
                    >
                      API & Developers
                    </Link>
                  </li>
                  <li>
                    <Link href="/help/" className="footer-link">
                      Help Center
                    </Link>
                  </li>
                  <li>
                    <Link href="/tutorials/" className="footer-link">
                      Tutorials
                    </Link>
                  </li>
                  <li>
                    <Link href="/resources/guides/" className="footer-link">
                      Guides
                    </Link>
                  </li>
                  <li>
                    <Link href="/newsletters/" className="footer-link">
                      Newsletters
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="https://livesession.statuspal.io/"
                      target="_blank"
                      className="footer-link"
                      rel="noopener noreferrer"
                    >
                      Status page
                    </Link>
                  </li>
                </Styled.List>
                <p className="footer-heading">Guides</p>
                <Styled.List className="list-unstyled m-0">
                  <li>
                    <Link href="/usability-testing/" className="footer-link">
                      Usability testing
                    </Link>
                  </li>
                  <li>
                    <Link href="/user-experience/" className="footer-link">
                      User experience
                    </Link>
                  </li>
                  <li>
                    <Link href="/heatmaps/" className="footer-link">
                      Heatmaps
                    </Link>
                  </li>
                </Styled.List>
              </div>
              <div>
                <p className="footer-heading">Legal</p>
                <Styled.List className="list-unstyled m-0">
                  <li>
                    <Link href="/terms-of-service/" className="footer-link">
                      Terms of Service
                    </Link>
                  </li>
                  <li>
                    <Link href="/privacy-policy/" className="footer-link">
                      Privacy Policy
                    </Link>
                  </li>
                  <li>
                    <Link href="/help/gdpr/" className="footer-link">
                      GDPR
                    </Link>
                  </li>
                  <li>
                    <Link href="/help/ccpa/" className="footer-link">
                      CCPA
                    </Link>
                  </li>
                  <li>
                    <Link href="/security/" className="footer-link">
                      Security
                    </Link>
                  </li>
                  <li>
                    <Link href="/opt-out/" className="footer-link">
                      Opt out
                    </Link>
                  </li>
                </Styled.List>
                <div className="desktop-view">
                  <p className="footer-heading">Company</p>
                  <Styled.List className="list-unstyled m-0">
                    <li>
                      <Link href="/demo/" className="footer-link">
                        Get a demo
                      </Link>
                    </li>
                    <li>
                      <Link href="/contact/" className="footer-link">
                        Contact Us
                      </Link>
                    </li>
                    <li>
                      <Link href="/jobs/" className="footer-link">
                        Careers
                      </Link>
                    </li>
                  </Styled.List>
                </div>
              </div>
            </Styled.GridBox>
            <div className="blog-footer">
              <p className="footer-heading">Blog</p>
              <Styled.List className="list-unstyled m-0">
                {edges.map((art) => (
                  <li key={art.node.frontmatter.url}>
                    <Link href={art.node.frontmatter.url} className="footer-link">
                      {art.node.frontmatter.title}
                    </Link>
                  </li>
                ))}
                <li>
                  <Link
                    href="/blog/"
                    className="footer-link with-arrow"
                    style={{ color: '#0a4ed6', fontWeight: 800 }}
                  >
                    See all articles
                  </Link>
                </li>
              </Styled.List>
            </div>
          </Styled.ContainerBox>
        </div>
      )}
      <div className="mt-4">
        <div className="container">
          {!adPage && <Styled.SubHeading>Start using LiveSession for free</Styled.SubHeading>}
          <Styled.Container adPage={adPage}>
            {!adPage && (
              <Styled.ExtendSignUpForm onSubmit={handleSubmit} className="signupbox">
                <label htmlFor="footer-newsletter">
                  <input
                    id="footer-newsletter"
                    type="email"
                    name="email"
                    placeholder="Your business email"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="input-email"
                  />
                </label>
                <CustomButton as="button" type="submit" secondary>
                  Sign up free
                </CustomButton>
              </Styled.ExtendSignUpForm>
            )}
            <Styled.Socials adPage={adPage}>
              <p>Follow us</p>
              <div>
                <a
                  href="https://twitter.com/livesessionhq"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={imgTwitter} alt="twitter" title="LiveSession on Twitter" />
                </a>
                <a
                  href="https://www.facebook.com/LiveSessionIO/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={imgFB} alt="facebook" title="LiveSession on Facebook" />
                </a>
                <a
                  href="https://www.linkedin.com/company/livesession/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={imgLI} alt="linkedin" title="LiveSession on LinkedIn" />
                </a>
                <a
                  href="https://www.instagram.com/livesessionhq/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={imgInsta} alt="instagram" title="LiveSession on Instagram" />
                </a>
                <a href="https://github.com/livesession" target="_blank" rel="noopener noreferrer">
                  <img src={imgGithub} alt="github" title="LiveSession on GitHub" />
                </a>
              </div>
            </Styled.Socials>
          </Styled.Container>
        </div>
      </div>
      <Styled.SubFooter>
        <div className="container">
          <p>
            LiveSession – High-quality session replay to analyze customer’s <br />
            behaviour across your sites and apps.
          </p>
          <p>Copyright © {new Date().getFullYear()} LiveSession. All rights reserved.</p>
        </div>
      </Styled.SubFooter>
    </footer>
  );
};

export default Footer;

Footer.propTypes = {
  adPage: PropTypes.bool,
};

Footer.defaultProps = {
  adPage: false,
};
