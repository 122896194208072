/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
// import Cookies from 'js-cookie';
import { graphql, useStaticQuery } from 'gatsby';
import Link from 'components/ui/link';
import Logo from 'components/layout/logo';
import Hamburger from 'components/layout/hamburger';
import Button from 'components/ui/button';
import optimization from 'img/solutions/icons/optimization.svg';
import tracking from 'img/solutions/icons/tracking.svg';
import testing from 'img/solutions/icons/testing.svg';
import debugging from 'img/solutions/icons/debugging.svg';
import support from 'img/solutions/icons/support.svg';
import marketing from 'img/solutions/icons/marketing.svg';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { useLocation } from '@reach/router';
import * as Styled from './styled';

const dropdown = [
  {
    name: 'Features',
    url: '/features/',
  },
  {
    name: 'Pricing',
    url: '/pricing/',
  },
  {
    name: 'Customers',
    url: '/customers/',
  },
  {
    name: 'Integrations',
    url: '/integrations/',
  },
  {
    name: 'Help',
    url: '/help/',
  },
  {
    name: 'Blog',
    url: '/blog/',
  },
  {
    name: 'Get a demo',
    url: '/demo/',
  },
];

// const COOKIE_DOMAIN =
//   process.env.GATSBY_HOST === 'http://localhost:8000' ? 'localhost' : '.livesession.io';
// const HIDE_BANNER_COOKIE = 'hide_banner';

// eslint-disable-next-line no-unused-vars
const Header = ({ font, photoColor, textBanner, addBanner, hideBorder }) => {
  const {
    blogpost: { edges },
  } = useStaticQuery(graphql`
    query HeaderBlogPost {
      blogpost: allJavascriptFrontmatter(
        filter: { frontmatter: { url: { regex: "/blog/" }, group: { ne: "updates" } } }
        sort: { fields: frontmatter___date, order: DESC }
        limit: 1
      ) {
        edges {
          node {
            frontmatter {
              url
              title
            }
          }
        }
      }
    }
  `);

  const article = edges[0].node.frontmatter;
  // const [isBarActive, setCookieBar] = useState(false);
  const [showMobileMenu, setMobileMenu] = useState(false);

  const navigation = useRef(null);
  const banner = useRef(null);
  const location = useLocation();

  const listenToScroll = useCallback(() => {
    const position = window.pageYOffset;
    if (addBanner && position > 10) {
      navigation.current.classList.add('fixed-bg-banner');
      banner.current.classList.add('hide-banner');
    } else if (addBanner && position < 10) {
      navigation.current.classList.remove('fixed-bg-banner');
      banner.current.classList.remove('hide-banner');
    }
    if (position > 50) {
      navigation.current.classList.add('fixed-bg');
    } else {
      navigation.current.classList.remove('fixed-bg');
    }
  }, [addBanner]);

  const handleMobileMenu = () => {
    if (window.innerWidth > 1024) {
      setMobileMenu(false);
    }
  };

  useEffect(() => {
    // const acceptedCookies = Cookies.get(HIDE_BANNER_COOKIE);
    // if (acceptedCookies !== '1') {
    //   setCookieBar(true);
    // }
    listenToScroll();
    window.addEventListener('scroll', listenToScroll);
    window.addEventListener('resize', handleMobileMenu);
    return () => {
      window.removeEventListener('scroll', listenToScroll);
      window.removeEventListener('resize', handleMobileMenu);
    };
  }, [listenToScroll]);

  // const handleHideBanner = () => {
  //   Cookies.set(HIDE_BANNER_COOKIE, '1', {
  //     path: '/',
  //     domain: COOKIE_DOMAIN,
  //     expires: 10000,
  //   });
  //   setCookieBar(false);
  // };
  return (
    <Styled.Navigation
      ref={navigation}
      style={{
        color: font,
        backgroundColor: showMobileMenu && '#fff',
        borderBottom: showMobileMenu && '1px solid #e4e5e8',
      }}
      className={hideBorder ? 'hide-border' : ''}
    >
      {addBanner && (
        <Styled.Banner ref={banner} href={article.url} target="_blank" rel="noopener noreferrer">
          <span>Blog</span>
          <p>{textBanner || article.title}</p>
          <Link withArrow yellow tag="p">
            Read more
          </Link>
          {/* <button type="button" onClick={handleHideBanner}>
          &#120;
        </button> */}
        </Styled.Banner>
      )}
      <Styled.Container className="container">
        <Styled.Box>
          <Logo
            beta
            listenToScroll={listenToScroll}
            photoColor={photoColor}
            showMobileMenu={showMobileMenu}
          />
          {location.pathname.split('/')[1] === 'integrations' ? (
            <Styled.Integrations href="/integrations/">
              <span>|</span>Integrations
            </Styled.Integrations>
          ) : null}
          <Styled.MenuButtonsContainer>
            <Styled.Menu>
              <Styled.Dropdown>
                <a href="/#" style={{ color: font }}>
                  Product
                </a>
                <Styled.DropdownMenu className="dropdown-menu" style={{ minWidth: 250 }}>
                  <li>
                    <Link href="/features/">Features</Link>
                  </li>
                  <Styled.SubMenu>
                    <a href="/#">Industries</a>
                    <ul>
                      <li>
                        <Styled.SubLink href="/industry/ecommerce/">
                          <p>
                            <span>eCommerce</span>
                            <span className="desc">
                              Sales tracking, cart recovery and conversion rate optimization
                            </span>
                          </p>
                        </Styled.SubLink>
                      </li>
                    </ul>
                  </Styled.SubMenu>
                  <Styled.SubMenu>
                    <a href="/solutions/">Solutions</a>
                    <ul className="solutions">
                      <li>
                        <Styled.SubLink tag={AnchorLink} to="/solutions#conversion-optimization">
                          <img src={optimization} alt="optimization" />
                          <p>
                            Conversion optimization
                            <span className="desc">Find opportunities for improvement</span>
                          </p>
                        </Styled.SubLink>
                      </li>
                      <li>
                        <Styled.SubLink tag={AnchorLink} to="/solutions#user-engagement-tracking">
                          <img src={tracking} alt="tracking" />
                          <p>
                            User engagement tracking
                            <span className="desc">Discover what works best</span>
                          </p>
                        </Styled.SubLink>
                      </li>
                      <li>
                        <Styled.SubLink tag={AnchorLink} to="/solutions#usability-testing">
                          <img src={testing} alt="testing" />
                          <p>
                            Usability testing
                            <span className="desc">See how users interact with your website</span>
                          </p>
                        </Styled.SubLink>
                      </li>
                      <li>
                        <Styled.SubLink tag={AnchorLink} to="/solutions#application-debugging">
                          <img src={debugging} alt="debugging" />
                          <p>
                            Application debugging
                            <span className="desc">Debug your application faster</span>
                          </p>
                        </Styled.SubLink>
                      </li>
                      <li>
                        <Styled.SubLink tag={AnchorLink} to="/solutions#customer-support">
                          <img src={support} alt="support" />
                          <p>
                            Customer support
                            <span className="desc">Improve your help center</span>
                          </p>
                        </Styled.SubLink>
                      </li>
                      <li>
                        <Styled.SubLink tag={AnchorLink} to="/solutions#performance-marketing">
                          <img src={marketing} alt="marketing" />
                          <p>
                            Performance marketing
                            <span className="desc">Boost your marketing results</span>
                          </p>
                        </Styled.SubLink>
                      </li>
                    </ul>
                  </Styled.SubMenu>
                  <Styled.SubMenu>
                    <a href="/#">Compare</a>
                    <ul>
                      <li>
                        <Styled.SubLink href="/compare/fullstory-alternative/">
                          <p>
                            <span>LiveSession vs FullStory</span>
                            <span className="desc">
                              Flexible plans, custom properties and more.
                            </span>
                          </p>
                        </Styled.SubLink>
                      </li>
                      <li>
                        <Styled.SubLink href="/compare/hotjar-alternative/">
                          <p>
                            <span>LiveSession vs Hotjar</span>
                            <span className="desc">
                              In-depth analytics, advanced segmentation and more.
                            </span>
                          </p>
                        </Styled.SubLink>
                      </li>
                    </ul>
                  </Styled.SubMenu>
                  <li>
                    <Link href="/integrations/">Integrations</Link>
                  </li>
                </Styled.DropdownMenu>
              </Styled.Dropdown>
              <li>
                <Link href="/pricing/" style={{ color: font }}>
                  Pricing
                </Link>
              </li>
              <li>
                <Link href="/customers/" style={{ color: font }}>
                  Customers
                </Link>
              </li>
              <Styled.Dropdown>
                <a href="/#" style={{ color: font }}>
                  Resources
                </a>
                <Styled.DropdownMenu className="dropdown-menu resources" as="div">
                  <ul className="dropdown-resource-container">
                    <li>
                      <Link href="/help/" style={{ color: font }}>
                        Help Center
                      </Link>
                    </li>
                    <li>
                      <Link
                        href="https://developers.livesession.io/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        API & Developers
                      </Link>
                    </li>
                    <li>
                      <Link href="/updates/">Updates</Link>
                    </li>
                    <li>
                      <Link
                        href="https://livesession.statuspal.io/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        System status
                      </Link>
                    </li>
                    <li>
                      <Link href="/partners/">Affiliate program</Link>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <Styled.SubLink href="/blog/">
                        <p>
                          <span>Blog</span>
                          <span className="desc">UX, conversion, analytics and more</span>
                        </p>
                      </Styled.SubLink>
                    </li>
                    <li>
                      <Styled.SubLink href="/resources/guides/">
                        <p>
                          <span>Guides</span>
                          <span className="desc">
                            In-depth guides about UX, Heatmaps, Usability Testing and more
                          </span>
                        </p>
                      </Styled.SubLink>
                    </li>
                    <li>
                      <Styled.SubLink href="/tutorials/">
                        <p>
                          <span>Tutorials</span>
                          <span className="desc">
                            Step-by-step tutorials to get even more from LiveSession
                          </span>
                        </p>
                      </Styled.SubLink>
                    </li>
                  </ul>
                </Styled.DropdownMenu>
              </Styled.Dropdown>
              <li>
                <Link href="/demo/" style={{ color: font }}>
                  Get a demo
                </Link>
              </li>
            </Styled.Menu>
            <Styled.DesktopButtons>
              <Styled.LoginButton href={process.env.GATSBY_APP_URL} className="log-in-button-pc">
                Log in
              </Styled.LoginButton>
              <Button secondary signUp sourceID="header" small>
                Sign up free
              </Button>
            </Styled.DesktopButtons>
          </Styled.MenuButtonsContainer>
        </Styled.Box>
        <Styled.MobileNavbar>
          <Logo
            beta
            listenToScroll={listenToScroll}
            photoColor={photoColor}
            showMobileMenu={showMobileMenu}
          />
          <Hamburger
            showMobileMenu={showMobileMenu}
            font={font}
            onClick={() => setMobileMenu(!showMobileMenu)}
          />
        </Styled.MobileNavbar>
        <Styled.MobileNavigation className={showMobileMenu && 'active'}>
          {dropdown.map(({ name, url }) => (
            <li key={url}>
              <Link href={url} style={{ color: font }}>
                {name}
              </Link>
            </li>
          ))}
          <Styled.Buttons>
            <Styled.SecondaryMobileButton
              href={process.env.GATSBY_APP_URL}
              className="mr-2"
              secondary
              small
            >
              Log in
            </Styled.SecondaryMobileButton>
            <Button secondary small signUp sourceID="header">
              Sign up free
            </Button>
          </Styled.Buttons>
        </Styled.MobileNavigation>
      </Styled.Container>
    </Styled.Navigation>
  );
};

export default Header;

Header.propTypes = {
  font: PropTypes.string.isRequired,
  photoColor: PropTypes.string.isRequired,
  textBanner: PropTypes.string,
  addBanner: PropTypes.bool,
  hideBorder: PropTypes.bool,
};

Header.defaultProps = {
  textBanner: null,
  addBanner: false,
  hideBorder: false,
};
