import React from 'react';
import { media } from 'theme/breakpoints';
import styled from 'styled-components';
import PropTypes from 'prop-types';
// import { Link as GatsbyLink } from 'gatsby';
import arrowIcon from 'img/ui/icon_arrow.svg';
import arrowIconLight from 'img/ui/icon_arrow_lightblue.svg';
import arrowIconYellow from 'img/ui/icon_arrow-yellow.svg';
import { useSourceParams } from 'hooks/useSourceParams';

export const StyledLink = styled.a`
  color: ${({ yellow }) => (yellow ? '#ffca00' : '#0a4ed6')};
  display: inline-block;
  font-weight: ${(props) => (props.withArrow ? 800 : 400)};
  cursor: pointer;
  ${media.mobile} {
    font-size: 16px;
  }
  &::after {
    content: ${({ withArrow, lightblue, yellow }) =>
      withArrow
        ? lightblue
          ? `url(${arrowIconLight})`
          : yellow
          ? `url(${arrowIconYellow})`
          : `url(${arrowIcon})`
        : null};
    display: inline-block;
    clear: both;
    margin-left: 16px;
    transition: 0.2s ease;
  }
  &.with-arrow {
    font-weight: 800;
    &::after {
      content: ${({ lightblue, yellow }) =>
        lightblue
          ? `url(${arrowIconLight})`
          : yellow
          ? `url(${arrowIconYellow})`
          : `url(${arrowIcon})`};
    }
  }
  &:hover {
    text-decoration: underline;
    color: ${({ yellow }) => (yellow ? '#ffca00' : '#0a4ed6')};
    &::after {
      transform: translateX(8px);
    }
  }
`;

const Link = ({
  href,
  target,
  rel,
  children,
  style,
  className,
  withArrow,
  tag,
  to,
  lightblue,
  sourceID,
  signUp,
  yellow,
}) => {
  // const internal = href && href.charAt(0) === '/' && !target && !rel;
  const { link } = useSourceParams(sourceID);

  return (
    <StyledLink
      style={style}
      href={signUp ? link : href}
      target={target}
      rel={rel}
      withArrow={withArrow}
      as={tag || null}
      to={to}
      lightblue={lightblue}
      yellow={yellow}
      className={[className, withArrow && 'with-arrow'].join(' ')}
    >
      {children}
    </StyledLink>
  );
};

export default Link;

Link.propTypes = {
  // eslint-disable-next-line consistent-return
  href: PropTypes.string,
  target: PropTypes.string,
  rel: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.array]).isRequired,
  style: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  className: PropTypes.string,
  tag: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  to: PropTypes.string,
  withArrow: PropTypes.bool,
  lightblue: PropTypes.bool,
  yellow: PropTypes.bool,
  signUp: PropTypes.bool,
  // eslint-disable-next-line consistent-return
  sourceID: (props, propName, componentName) => {
    if (props.signUp === true && typeof props[propName] !== 'string') {
      return new Error(`Please provide a sourceID instead of href in ${componentName}!`);
    }
  },
};

Link.defaultProps = {
  target: undefined,
  rel: undefined,
  style: null,
  className: null,
  to: null,
  tag: undefined,
  href: undefined,
  withArrow: false,
  lightblue: undefined,
  yellow: undefined,
  sourceID: undefined,
  signUp: undefined,
};
