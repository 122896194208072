import styled from 'styled-components';
import { media } from 'theme/breakpoints';
import { StyledButton } from 'components/ui/button';
import { SignUpForm } from 'components/styled/SignUpForm';

export const StartUsing = styled.section`
  display: flex;
  width: 100%;
  background-color: #7dffef;
  justify-content: space-between;
  overflow: hidden;
  padding: 72px 80px;
  border-radius: 4px;
  margin-top: ${(props) => (props.deleteMarginTop ? 0 : '120px')};
  align-items: center;
  margin-bottom: 144px;
  ${media.desktop} {
    flex-direction: column;
  }
  ${media.tablet} {
    margin-top: ${(props) => (props.deleteMarginTop ? 0 : '100px')};
    padding: 40px 20px;
    margin-bottom: 100px;
    width: calc(100% - 32px);
  }
  h2 {
    flex-basis: 50%;
    margin-right: 80px;
    line-height: 54px;
    ${media.tablet} {
      font-size: 28px;
      line-height: 42px;
      & span {
        display: none;
      }
    }
    ${media.desktop} {
      text-align: center;
      margin-right: 0;
    }
  }
  .start {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    width: 100%;
    flex-basis: 50%;

    .checkmarks {
      margin: 20px 0 0;
      display: grid;
      grid-template-columns: repeat(3, max-content);
      grid-column-gap: 53px;
      justify-content: center;
      ${media.tablet} {
        grid-column-gap: 18px;
      }
      p {
        margin-bottom: 0;
        display: flex;
        font-size: 14px;
        position: relative;
        text-align: center;
        line-height: normal;
        & span {
          margin-left: 4px;
          ${media.mobile} {
            display: none;
          }
        }
        &::after {
          content: '';
          width: 5px;
          height: 5px;
          border-radius: 50%;
          position: absolute;
          background-color: #000;
          top: 50%;
          right: -29px;
          transform: translateY(-50%);
          ${media.tablet} {
            right: -11px;
          }
        }
        &:nth-child(3) {
          &::after {
            display: none;
          }
        }
      }
    }
  }
`;

export const ExtendSignUpForm = styled(SignUpForm)`
  & label input {
    width: 314px;
    border: 2px solid #000;
    transition: 0.2s ease;
    &:hover {
      border-color: #000;
    }
    ${media.mobile} {
      width: 100%;
      margin-bottom: 16px;
      border-radius: 4px;
    }
  }
  ${media.desktop} {
    margin-top: 32px;
  }
`;

export const CustomButton = styled(StyledButton)`
  ${media.tablet} {
    width: 100% !important;
    max-width: 314px !important;
  }
  ${media.mobile} {
    max-width: -webkit-fill-available !important;
  }
`;
